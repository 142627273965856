import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer" >
            <span>R2S Version v1.0.1 (Avesis us-west-2) </span>
        </footer>
    )
}

export default Footer